import React from 'react'
import './ServiceCard.css'
import { withRouter } from 'react-router-dom'

const ServiceCard = ({ item, history }) => {
    const { title, icon, heading1, heading2, heading3, subheading1, subheading2, subheading3, entryLink } = item

    const onClick = () => {
        if (entryLink?.length > 0) {
            history.push(entryLink[0].uri)
        }
    }

    return (
      <div className={'service-card-wrapper'} onClick={onClick}>
        <div className={'service-card'}>
            <div className={'service-card-header'}>
                <img src={icon} className={'service-card-header-icon'} alt={'Service Icon'}/>
                <h4 className={'service-card-header-title'}>{title}</h4>
            </div>
            <div className={'service-card-body'}>
                <div className={'service-card-row'}>
                    <p className={'service-card-row-heading'}>{heading1}</p>
                    <p className={'service-card-row-subheading'}>{subheading1}</p>
                </div>
                <div className={'service-card-row'}>
                    <p className={'service-card-row-heading'}>{heading2}</p>
                    <p className={'service-card-row-subheading'}>{subheading2}</p>
                </div>
                <div className={'service-card-row'}>
                    <p className={'service-card-row-heading'}>{heading3}</p>
                    <p className={'service-card-row-subheading'}>{subheading3}</p>
                </div>
            </div>
        </div>
      </div>
    )
}

export default withRouter(ServiceCard)
