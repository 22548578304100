import React, {Component} from 'react';

import '../../fonts/fonts.css';
import './HtmlDocument.css';

class HtmlDocument extends Component {
    componentDidMount() {

    }

    render() {
        return (
            <div className={"html-wrapper"}>
                {this.props.innerHtml}
            </div>
        );
    }
}

export default HtmlDocument;