import React, { useRef } from 'react'
import './WhatWeDo.css'
import ServiceCard from './components/ServiceCard'

const WhatWeDo = ({ heading, backgroundImage, items }) => {
    const dividerRef = useRef(null)
    const dividerOffset = dividerRef?.current?.height && -dividerRef?.current?.height/2

    return (
        <div id={'wwd-container'}>
            <img id={'wwd-background-overlay'} src={backgroundImage[0].url} alt={'Service Background'}/>
            <div id={'wwd-title-container'}>
                <p id={'wwd-title'}>{heading}</p>
            </div>
            <div id={'wwd-grid-card-container'}>
                {items.map(item => <ServiceCard item={item} />)}
            </div>
            <img ref={dividerRef} style={{ bottom: dividerOffset }} id={'wwd-stripe-overlay'} src={'./images/Stripes.png'} alt={'Section Divider'}/>
        </div>
    )
}

export default WhatWeDo