import React, {Component} from 'react';
import './ComputerAnimation.css'
/*import {
    getCanvasHeight,
    getCanvasWidth,
    getComputerAnimationCanvasRec, setComputerAnimationCanvasRec, setParentElement, drawImage, initializeCanvas
} from "./CompAnimationLayout"*/
import $ from "jquery"
import ScrollMagic from "../../../plugins/ScrollMagic"
import TweenMax from 'gsap';
import SloganItem from './sloganItem/SloganItem'
import {isMobile} from "mobile-device-detect"

class ComputerAnimation extends Component {
    constructor(props) {
        super(props)

        this.canvas = React.createRef()
    }

    render() {
        const { list, image, description } = this.props
        console.log(image)

        return (
            <div className={'computer-animation'} ref='sectionRef' id={'computerAnimation'}>
                <div className={'ca-content'}>
                    <div className={'ca-columns'}>
                        <div className={'ca-column'} style={{width: '60%'}}>
                            <div id={'who-are-we-canvas-content'}>
                                <div id={'who-are-we-description'} >
                                    <span id={'who-are-we-description-text'}>{description}</span>
                                </div>
                                <div id={'who-are-we-image-wrapper'}>
                                    <img id={'who-are-we-image'} src={image.url}/>
                                </div>
                            </div>
                        </div>
                        <div className={'ca-column'} style={{width: '40%'}}>
                            <ul className={'slogan-list'}>
                                {list.map((item, key) => {
                                    return (
                                        <SloganItem heading={item.header}
                                            text={item.body}
                                            key={key}
                                        />
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ComputerAnimation
