import React, { Component } from 'react'
import { withSizes } from '@oos-studio/website-common-components'
import { setStyleClasses } from '../../utils/HTMLTags'

class HTMLBlurb extends Component {
  state = {
    text: '',
  }

  componentDidMount() {
    const isDarkTheme = this.props.theme === 'dark'

    this.setState({
      text: setStyleClasses(this.props.content, false, isDarkTheme),
      highlightText: setStyleClasses(this.props.content, true, isDarkTheme)
    })
  }

  render() {
    const { alignment, getStyle, theme, backgroundColor } = this.props
    const { text } = this.state

    const isDarkTheme = theme === 'dark'

    return (
      <div className={isDarkTheme ? `html-blurb-${alignment} dark` : `html-blurb-${alignment}`} style={{...getStyle(styles.container),
        backgroundColor: backgroundColor,
        justifyContent: alignment === 'left' ? 'flex-start' : alignment === 'right' ? 'flex-end' : 'center',
      }}>

          <div style={{ ...styles.text, textAlign: alignment, }} dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 25,
    paddingBottom: 0,
    md: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  text: {
    width: '100%',
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}

export default withSizes(HTMLBlurb)
