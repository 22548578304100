import React, {Component} from 'react';
import './Logos.css'
import { gqlConnect } from 'react-connect-graphql-beta'

class LandscapeLogoOverPrimary extends Component {
    render() {
        return (
            <img src={this.props.gql.data[0].landscapeLogoOverPrimary[0].url} id={"logoImageNav"}/>
        )
    }
}

export let landscapeLogoOverPrimary = gqlConnect('logos')(LandscapeLogoOverPrimary)

class PortraitLogoOverPrimary extends Component {
    render() {
        return (
            <img src={this.props.gql.data[0].portraitLogoOverPrimary[0].url} id={"logoImage"}/>
        );
    }
}

export let portraitLogoOverPrimary = gqlConnect('logos')(PortraitLogoOverPrimary)

class IconOverDark extends Component {
    render() {
        return (
            <div className={"col-md-12 col-sm-10 col-xs-10 oos-logo"}>
                <img className={"logo"} id={"oos-logo"} src={this.props.gql.data[0].iconOverDark[0].url}/>
            </div>
        )
    }
}

export let iconOverDark = gqlConnect('logos')(IconOverDark)

