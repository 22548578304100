import React, { useState, useEffect } from 'react'
import NavigationBarMobile from './mobile/NavigationBar'
import NavigationBarDesktop from './desktop/NavigationBar'
import useWindowSize from '../../hooks/useWindowSize'

const NavigationBarSelector = () => {
  const [isMobile, setIsMobile] = useState(false)

  const size = useWindowSize()

  useEffect(() => {
    if(size.width <= 1185 && !isMobile) {
      setIsMobile(true)
    } else if (size.width > 1185 && isMobile) {
      setIsMobile(false)
    }
  }, [size])

  return isMobile ? <NavigationBarMobile /> : <NavigationBarDesktop />
}

export default NavigationBarSelector
