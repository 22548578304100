import React, {Component} from 'react';

import '../../fonts/fonts.css';
import './NotFound.css';
import $ from "jquery";

class NotFound extends Component {
    componentDidMount() {
        // Fade out the splash screen
        //$("#splashScreen").fadeOut();
        /*$(".navbar-links").hide();
        $("#navbar-toggle-button").hide();*/
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        /*if (window.innerWidth < 768) {
            $("#navbar-toggle-button").show();
        } else {
            $(".navbar-links").show();
        }*/
    }

    render() {
        return (
            <div className={"not-found"}>
                <div id={"not-found-container"}>
                    <div className={"center"}>
                        <div className={"not-found-body"}>
                            <span>oops, we could not find your page</span>
                        </div>
                        <div className={"not-found-header"}>
                            <span>Error 404</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFound;