import React, { useState, useEffect } from 'react'
import { gqlConnect } from 'react-connect-graphql-beta'
import { withRouter, Link } from 'react-router-dom'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from 'reactstrap'
import { landscapeLogoOverPrimary as LandscapeLogoOverPrimary } from '../../globals/logos/Logos'
import SimpleNavList from './SimpleNavList'
import { scrollToContactForm } from '../../../utils/ScrollToContactForm'
import MegaMenu from './MegaMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/pro-regular-svg-icons'

const NavigationBarMobile = ({ gql, history }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [activeList, setActiveList] = useState(-1)
  const { navigationBarItems } = gql.data[0]

  const _styles = styles(menuOpen)

  useEffect(() => {
    setMenuOpen(false)
  }, [history.location.pathname])

  useEffect(() => {
    setActiveList(-1)
  }, [menuOpen])

  const onClickList = (index) => {
    if (activeList === index) {
      setActiveList(-1)
    } else {
      setActiveList(index)
    }
  }

  const renderNavItem = (item, index) => {
    if (item.menu.length > 0) {
      return (
        <NavItem
          onClick={() => onClickList(index)}
          style={{
            ..._styles.navItem,
            padding: 0,
            backgroundColor: 'transparent',
          }}>
          <div style={_styles.subMenuTitleWrapper}>
            <div style={{ paddingLeft: 32, position: 'relative' }}>
              {item.displayName}
              <p
                style={{
                  ..._styles.caret,
                  transform:
                    activeList === index ? 'rotate(-135deg)' : 'rotate(45deg)',
                  marginTop: activeList === index ? 15 : 9,
                }}
              />
            </div>
          </div>
          <MegaMenu data={item.menu} display={activeList === index} />
        </NavItem>
      )
    } else if (item.navigationBarItemEntry.length > 1) {
      return (
        <NavItem
          onClick={() => onClickList(index)}
          style={{
            ..._styles.navItem,
            padding: 0,
            backgroundColor: 'transparent',
          }}>
          <div style={{ ..._styles.subMenuTitleWrapper, paddingBottom: 15 }}>
            <div style={{ paddingLeft: 32, position: 'relative' }}>
              {item.displayName}
              <p
                style={{
                  ..._styles.caret,
                  transform:
                    activeList === index ? 'rotate(-135deg)' : 'rotate(45deg)',
                  marginTop: activeList === index ? 15 : 9,
                }}
              />
            </div>
          </div>
          <SimpleNavList
            list={item.navigationBarItemEntry}
            display={activeList === index}
          />
        </NavItem>
      )
    } else if (item.navigationBarItemEntry) {
      return (
        <Link
          to={`/${item.navigationBarItemEntry[0].uri}`}
          style={{ textDecoration: 'none' }}>
          <NavItem style={_styles.navItem}>{item.displayName}</NavItem>
        </Link>
      )
    } else if (item.navigationBarItemUrl) {
      return (
        <NavItem
          onClick={() => window.open(item.navigationBarUrl, '__blank')}
          style={_styles.navItem}>
          {item.displayName}
        </NavItem>
      )
    }
  }

  const onClickContact = () => {
    scrollToContactForm(history)
    setMenuOpen(false)
  }

  return (
    <Navbar style={_styles.container}>
      <Link to={'/'} style={_styles.brand} onClick={() => setMenuOpen(false)}>
        <LandscapeLogoOverPrimary />
      </Link>
      <NavbarToggler
        style={_styles.toggler}
        className={'navigation-toggler'}
        onClick={() => setMenuOpen(!menuOpen)}>
        <span className={'navbar-toggler-icon'}>
          <FontAwesomeIcon
            icon={menuOpen ? faTimes : faBars}
            style={{ color: 'white', fontSize: menuOpen ? 35 : 32 }}
          />
        </span>
      </NavbarToggler>{' '}
      <Collapse style={_styles.collapse} isOpen={menuOpen} navbar>
        <Nav style={_styles.nav} navbar>
          {navigationBarItems.map((item, index) => {
            return item.__typename === 'navigationBarItems_item_BlockType'
              ? renderNavItem(item, index)
              : null
          })}
          <div
            onClick={onClickContact}
            style={{ ..._styles.navItem, paddingBottom: 25 }}>
            Let's Talk
          </div>
        </Nav>
      </Collapse>
    </Navbar>
  )
}

const styles = (menuOpen) => {
  return {
    container: {
      top: 0,
      zIndex: 10000,
      position: 'fixed',
      width: '100%',
      height: 65,
      paddingTop: 0,
      paddingBottom: 12,
      backgroundColor: '#6AE1C6',
    },
    brand: {
      paddingTop: 0,
      paddingLeft: 6,
      transition: 'opacity 0.5s',
    },
    collapse: {
      marginLeft: -16,
      marginRight: -16,
      marginTop: -5,
      backgroundColor: 'transparent',
    },
    nav: {
      backgroundColor: 'transparent',
      paddingBottom: 15,
      height: '100vh',
      overflowY: 'scroll',
    },
    subMenuTitleWrapper: {
      display: 'flex',
      backgroundColor: '#6AE1C6',
      paddingTop: 15,
      paddingBottom: 25,
    },
    navItem: {
      color: 'white',
      fontSize: 22,
      fontFamily: "'Gotham A', 'Gotham B'",
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 32,
      paddingRight: 32,
      width: '100%',
      backgroundColor: '#6AE1C6',
      cursor: 'pointer',
    },
    toggler: {
      outline: 'none',
      paddingRight: 16,
      paddingBottom: menuOpen ? 12 : 9,
    },
    caret: {
      position: 'absolute',
      left: 'calc(100% + 5px)',
      border: 'solid white',
      borderWidth: '0 2px 2px 0',
      display: 'inline-block',
      padding: 4,
      transition: 'transform 0.15s',
      marginLeft: 10,
    },
    toggleOpen: {
      width: 25,
      filter: 'invert(1)',
    },
    toggleClosed: {
      width: 30,
      filter: 'invert(1)',
    },
  }
}

export default withRouter(gqlConnect('navigationBar')(NavigationBarMobile))
