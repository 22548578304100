import React from 'react'
import createReactClass from 'create-react-class'
import {
  DefaultPage,
  BlogListPage
} from '../components/pages'

const pages = {
  blogListPage: BlogListPage,
  defaultPage: DefaultPage
}

export const getPage = (type) => {
  const PageComp = pages[type]
  return createReactClass({
    displayName: 'Page',
    render: function () {
      return <PageComp key={type} />
    },
  })
}
