import React, { Component } from 'react'
import DocumentMeta from '../../common/DocumentMeta'
import ContactUs from '../contactUs/ContactUs'
import {Header} from '@oos-studio/website-common-components'
import ContentBlock from './ContentBlock'
import HTMLBlurb from './HTMLBlurb'
import { Article, withSizes } from '@oos-studio/website-common-components'
import Story from './Story'

class ModularPage extends Component {
  skipIndex = []

  componentDidMount() {
    this.skipIndex = this.props.gql.entry.solutionsBody?.map(block => {
      return false
    })
  }

  getHeader = () => {
    const { entry } = this.props.gql
    const { sm } = this.props

    return {
      backgroundAsset: {
        url: entry.solutionsHeroImage.length > 0 ? entry.solutionsHeroImage[0].url : null,
        mimeType: 'image/png',
      },
      styles: sm ? {
        container: {
          height: 300,
        },
        backgroundAsset: {
          height: 300,
          objectPosition: entry.headerAlignment,
        },
      } : {
        container: {
          height: 500,
        },
        backgroundAsset: {
          zIndex: -1,
        },
      },
    }
  }

  renderHTMLWithAsset = (asset, html, index) => {
    const { getStyle, md } = this.props

    this.skipIndex[index] = true

    const articleData = {
      dropCap: false,
      newsPostBody: [{
        typeHandle: 'asset',
        text: '',
        asset: asset.asset,
        caption: asset.caption,
        position: asset.position,
      }, {
        typeHandle: 'text',
        text: html.blurbText,
        asset: null,
        caption: null,
        position: null,
      },]
    }

    const isDarkTheme = html.theme === 'dark'

    return (
      <div className={isDarkTheme ? 'content-block dark' : 'content-block'} style={{
        ...getStyle(styles.articleWrapper),
        backgroundColor: asset.position === 'center' ? 'rgba(0,0,0,0)' : html.backgroundColor[0].colorValue,
      }}>
        <Article data={articleData} styles={{
          ...getStyle(styles.article),
          paragraph: {
            container: {
              backgroundColor: asset.position === 'center' || md ? html.backgroundColor[0].colorValue : 'rgba(0,0,0,0)',
              paddingLeft: md ? 30 : 0,
              paddingRight: md ? 30 : 0,
              marginLeft: md ? -30 : 0,
              marginRight: md ? -30 : 0,
              paddingTop: md ? 45 : 0,
              paddingBottom: md ? 45 : 0,
            },
          },
          supplement: {
            content: {
              imgCaption: {
                color: isDarkTheme && !md ? '#7FBAC4' : '#007589',
              },
            },
          },
        }} />
      </div>
    )
  }

  renderAsset = (asset) => {
    const { getStyle } = this.props

    const articleData = {
      dropCap: false,
      newsPostBody: [{
        typeHandle: 'asset',
        text: '',
        asset: asset.asset,
        caption: asset.caption,
        position: asset.position,
        autoplay: asset.autoplay,
        loop: asset.loop,
        controls: asset.controls,
      },]
    }

    return (
      <div style={getStyle(styles.imgArticleWrapper)}>
        <Article data={articleData} styles={getStyle(styles.imgArticle)} />
      </div>
    )
  }

  render() {
    const { getHeader, renderHTMLWithAsset, renderAsset } = this
    const { entry } = this.props.gql
    const { skipIndex } = this

    let meta = {}
    if(entry) {
      meta = {
        title: entry.title,
        description: entry.solutionsDescription || '',
        meta: {
          charset: 'utf-8',
          name: {
            keywords: entry.solutionsKeywords || '',
          },
          itemProp: {
            name: entry.title,
            description: entry.solutionsDescription || '',
            image: entry.solutionsHeroImage.length > 0 ? entry.solutionsHeroImage[0].url : null,
          },
          property: {
            'og:title': entry.title,
            'og:type': 'solution',
            'og:image': entry.solutionsHeroImage.length > 0 ? entry.solutionsHeroImage[0].url : null,
            'og:site_name': 'oos',
            'og:description': entry.solutionsDescription || '',
            'og:url': window.location.href,
            'fb:app_id': '2317018421941218',
          },
        },
        auto: {
          ograph: true
        }
      }
    }
    return (
      <DocumentMeta key={window.location.pathname} {...meta}>
        <Header {...getHeader()}/>
        <div style={styles.container}>
          {entry.solutionsBody.map((block, index) => {
            const textFollowsAsset = block.__typename === 'solutionsBody_solutionsAsset_BlockType' && entry.solutionsBody[index + 1]?.__typename === 'solutionsBody_textBlurb_BlockType'
            const type = block.__typename

            if(type === 'solutionsBody_textBlurb_BlockType') {
              if(!skipIndex[index - 1]) {
                return <HTMLBlurb content={block.blurbText}
                                  alignment={block.blurbAlignment}
                                  backgroundColor={block.backgroundColor[0].colorValue}
                                  theme={block.theme}/>
              }
            }
            else if (type === 'solutionsBody_solutionsAsset_BlockType') {
              if(textFollowsAsset) {
                return renderHTMLWithAsset(block, entry.solutionsBody[index + 1], index)
              }

              return renderAsset(block)
            }
            else if (type === 'solutionsBody_story_BlockType') {
              const storyData = {
                image: block.image,
                title: block.storyTitle,
                subtitle: block.storySubtitle,
                text: block.storyText,
                buttonText: block.buttonText || 'Read Story',
                buttonUrl: block.buttonUrl,
                buttonEntry: block.buttonEntry,
                autoplay: block.autoplay,
                controls: block.controls,
                loop: block.loop,
              }
              return <Story story={storyData} />
            } else {
              return <ContentBlock key={index} data={block} />
            }
          })}
        </div>
        <ContactUs />
      </DocumentMeta>
    )
  }
}

const styles = {
  container: {
    backgroundColor: '#FFFFFF',
    position: 'relative',
  },
  articleWrapper: {
    width: '100%',
    paddingLeft: 60,
    paddingRight: 60,
    md: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  article: {
    container: {
      borderTopWidth: 0,
      paddingTop: 25,
      paddingBottom: 25,
      paddingLeft: 0,
      paddingRight: 0,
      margin: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 1200,
      width: '100%',
    },
  },
  imgArticleWrapper: {
    width: '100%',
    paddingLeft: 60,
    paddingRight: 60,
    md: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  imgArticle: {
    container: {
      borderTopWidth: 0,
      paddingTop: 25,
      paddingBottom: 25,
      paddingLeft: 0,
      paddingRight: 0,
      margin: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 1200,
      width: '100%',
    },
    content: {
      width: '100%',
    },
    supplement: {
      content: {
        imgCaption: {
          color: '#007589',
        },
      },
    },
  },
}

export default withSizes(ModularPage)
