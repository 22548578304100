import React, {Component} from 'react';
import $ from 'jquery';
import ScrollMagic from "../../plugins/ScrollMagic.js";
import '../../fonts/fonts.css';
import './ContactUs.css';
import TweenLite, {Power2} from "gsap";
import {detect} from "detect-browser";
import {gqlConnect} from 'react-connect-graphql-beta'
import {Col, Row} from 'reactstrap'
import { connect } from 'react-redux'
import {setName, setEmail, setPhoneNumber, setCompany, setMessage, submitInquiry, setStatusMessage } from './actions'
import * as EmailValidator from 'email-validator'

function scrollToElement(el) {
  $('#container1').animate({
    scrollTop: $('#container1').scrollTop() + $(`#${el.id}`).offset().top - ($(window).height() * .25)
  })
}

class ContactUs extends Component {
  componentDidMount() {
      this.controller = new ScrollMagic.Controller(/*{container: "#container1"}*/);
      let container = document.getElementById('container1');
      this.controller.scrollTo(function (newpos, duration) {
          TweenLite.to(container, duration, {scrollTo: {y: newpos, autoKill:false}, ease: Power2.easeOut});
      });
  }



  onFocus(event) {
    const browser = detect();
    let el = event.target;
    if (browser.name === 'ios') {
        setTimeout(() => {
            scrollToElement(el)
        }, 50);
    }
  }

  onChangeName = (name) => {
    const { setName, setStatusMessage } = this.props
    setName(name)
    setStatusMessage('')
  }

  onChangeEmail = (email) => {
    const { setEmail, setStatusMessage } = this.props
    setEmail(email)
    setStatusMessage('')
  }

  onChangePhone = (phone) => {
    const { setPhoneNumber, setStatusMessage } = this.props
    setPhoneNumber(phone)
    setStatusMessage('')
  }

  onChangeMessage = (message) => {
    const { setMessage, setStatusMessage } = this.props
    setMessage(message)
    setStatusMessage('')
  }

  onChangeCompany = (company) => {
    const { setCompany, setStatusMessage } = this.props
    setCompany(company)
    setStatusMessage('')
  }

  validateInquiry = (inquiry) => {
    console.log(inquiry)
    let success = false
    let error = ''

    if(inquiry.name === "") {
      error = 'Please include your name.'
    } else if (inquiry.email === "") {
      error = 'Please include your email address.'
    } else if (!EmailValidator.validate(inquiry.email)) {
      error = 'Email address is not formatted correctly.'
    } else if (inquiry.company === "") {
      error = 'Please include your company/organization name.'
    } else if (inquiry.message === "") {
      error = 'Please include a message.'
    } else {
      success = true
    }

    return {
      success: success,
      error: error,
    }
  }

  onSubmit = () => {
    const { name, email, phoneNumber, message, company, submitInquiry, setStatusMessage } = this.props
    const { validateInquiry } = this

    const inquiry = {
      name: name,
      email: email,
      phone: phoneNumber,
      message: message,
      company: company,
    }

    const validate = validateInquiry(inquiry)

    if(validate.success) {
      submitInquiry(inquiry)
    } else {
      setStatusMessage(validate.error)
    }
  }

  render() {
    const { onChangeName, onChangePhone, onChangeEmail, onChangeMessage, onChangeCompany, onSubmit } = this
    const { isSending, statusMessage } = this.props

    return (
      <div id={"contact-us-form"}>
        <div id={"contact-us-container"} style={this.props.style}>
          <Row>
            <Col xs={1} className={'d-md-none'} />
            <Col id={"contact-us-header"} xs={10} md={12}>
              <span id={"contact-us-header"}>Let's Talk</span>
            </Col>
            <Col xs={1} className={'d-md-none'} />
          </Row>
          <Row>
            <Col xs={1} className={'d-md-none'} />
            <Col xs={10} md={12}>
              <div id={"contact-us-alert"} style={{display:"none"}}>
                <span id={"contact-us-alert"} />
              </div>
            </Col>
            <Col xs={1} className={'d-md-none'} />
          </Row>
          <Row>
            <Col xs={1} className={'d-md-none'} />
            <Col xs={10} md={6}>
              <Row>
                <Col className={"contact-us"}>
                  <input onChange={e => onChangeName(e.target.value)} className={"contact-us"} id={"name"} onFocus={this.onFocus} placeholder={"Name*"}/>
                  <span className={"contact-us-err contact-us-err-hide"} id={"name-err"}>x</span>
                </Col>
              </Row>
              <Row>
                <Col className={"contact-us"}>
                  <input onChange={e => onChangeCompany(e.target.value)} className={"contact-us"} id={"company"} onFocus={this.onFocus} placeholder={"Company*"}/>
                  <span className={"contact-us-err contact-us-err-hide"} id={"company-err"}>x</span>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <Row>
                <Col xs={1} className={'d-md-none'} />
                <Col xs={10} md={12} className={"contact-us"}>
                  <input onChange={e => onChangeEmail(e.target.value)} className={"contact-us"} id={"email"} onFocus={this.onFocus} placeholder={"Email Address*"}/>
                  <span className={"contact-us-err contact-us-err-hide"} id={"email-err"}>x</span>
                </Col>
                <Col xs={1} className={'d-md-none'} />
              </Row>
              <Row>
                <Col xs={1} className={'d-md-none'} />
                <Col xs={10} md={12} className={"contact-us"}>
                  <input onChange={e => onChangePhone(e.target.value)} className={"contact-us"} id={"phone"} onFocus={this.onFocus} placeholder={"Phone Number"}/>
                  <span className={"contact-us-err contact-us-err-hide"} id={"phone-err"}>x</span>
                </Col>
                <Col xs={1} className={'d-md-none'} />
              </Row>
            </Col>
            <Col xs={1} className={'d-md-none'} />
          </Row>
          <Row>
            <Col xs={1} className={'d-md-none'} />
            <Col xs={10} md={12}>
              <textarea onChange={e => onChangeMessage(e.target.value)} className={'contact-us'} id={'message'} onFocus={this.onFocus} placeholder={'Message*'} />
              <span className={"contact-us-err contact-us-err-hide"} id={"message-err"}>x</span>
            </Col>
            <Col xs={1} className={'d-md-none'} />
          </Row>
          <Row>
            <Col xs={1} className={'d-md-none'} />
            <Col xs={10} md={12}>
              <button className={isSending ? "submit-contact-us sending" : "submit-contact-us"} onClick={() => onSubmit()}>{isSending ? 'Sending...' : 'Submit'}</button>
            </Col>
            <Col xs={1} className={'d-md-none'} />
          </Row>
        </div>
        <div style={{ fontFamily: 'Gotham A, Gotham B', fontSize: 18, color: '#4d4e56', fontWeight: 400, marginTop: 15, width: '100%', display: 'flex', justifyContent: 'center', }}>{statusMessage}</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    name: state.contactUs.name,
    email: state.contactUs.email,
    phoneNumber: state.contactUs.phoneNumber,
    message: state.contactUs.message,
    company: state.contactUs.company,
    isSending: state.contactUs.isSending,
    statusMessage: state.contactUs.statusMessage,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setName: name => dispatch(setName(name)),
    setEmail: email => dispatch(setEmail(email)),
    setPhoneNumber: phone => dispatch(setPhoneNumber(phone)),
    setCompany: company => dispatch(setCompany(company)),
    setMessage: message => dispatch(setMessage(message)),
    submitInquiry: inquiry => dispatch(submitInquiry(inquiry)),
    setStatusMessage: message => dispatch(setStatusMessage(message)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(gqlConnect('contactForm')(ContactUs));
