import React, {Component} from 'react';

import '../../fonts/fonts.css';
import './Footer.css';
import { withRouter } from "react-router-dom"
import FooterContainer from './FooterContainer'
import $ from "jquery"

class Footer extends Component {

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll)
        window.addEventListener('resize', this.onResize)

        let lastContainerHeight = 0

        let that = this
        this.marginElement = $('.footer-bottom-margin-element')

        setInterval(function() {
            let containerHeight = $('#footer-container').innerHeight()
            if (lastContainerHeight !== containerHeight) {
                that.marginElement.css('margin-bottom', `${containerHeight}px`);
                $('.footer-wrapper').css('opacity', '1')
                lastContainerHeight = containerHeight

            }
            that.onScroll()
        }, 50)
    }

    onResize = () => {
        this.marginElement.css('margin-bottom', `${$('#footer-container').innerHeight()}px`);
        this.onScroll()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.onScroll()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onScroll()
    }

    onScroll = () => {
        let st = window.pageYOffset || document.documentElement.scrollTop

        if (st > $('.App').innerHeight() - window.innerHeight - 100) {
            $('.footer-background').css('z-index', '0')
            $('.footer-wrapper').css('z-index', '0')
            $('.footer-wrapper').css('opacity', '1')
        } else {
            $('.footer-background').css('z-index', '-5')
            $('.footer-wrapper').css('z-index', '-5')
        }
    }

    render() {

        return (
          <div className={"footer-wrapper"}>
              <div className={'footer-background'} style={{position: 'fixed', bottom: 0, left: 0, right: 0}}>
                <FooterContainer id={'footerContainer'} className={'footer-container'} style={{position: 'absolute', bottom: 0, left: 0, right: 0}} ref={'footerContainer'}/>
              </div>
              <div className={'footer-cover'}/>
          </div>
        );
    }
}

export default withRouter(Footer);
