import React, {useEffect, useState} from 'react'
import SimpleNavList from './SimpleNavList'
import { withRouter, Link } from 'react-router-dom'
import MegaMenu from './MegaMenu'
import {useSelector} from 'react-redux'

const NavigationBarItem = ({item, key, history}) => {
  const [isHovered, setIsHovered] = useState(false)

  const _styles = styles(isHovered)

  const navState = useSelector(state => state.navigation)

  useEffect(() => {
    setIsHovered(false)
  }, [history.location.pathname])

  const onClickItem = () => {
    if(item.menu) {
      return
    } else if (item.navigationBarItemEntry) {
      if(item.navigationBarItemEntry.length > 1) {
        return
      } else {
        history.push(`/${item.navigationBarItemEntry[0].uri}`)
      }
    } else if(item.navigationBarItemUrl) {
      window.open(item.navigationBarItemUrl, "_blank")
    }
  }

  const onMouseLeave = () => {
    setIsHovered(false)
  }

  const onMouseEnter = () => {
    setIsHovered(true)

  }

  return item.__typename === 'navigationBarItems_item_BlockType' ? (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {item.navigationBarItemEntry && item.navigationBarItemEntry.length === 1 ?
        <Link to={`/${item.navigationBarItemEntry[0].uri}`} style={_styles.linkWrapper}>
          <div onClick={() => onClickItem()}  key={key} style={_styles.itemWrapper}>
            {item.displayName}
            {item.menu && item.menu.length > 0 && <p style={_styles.caret} />}
            {item.navigationBarItemEntry.length > 1 && <SimpleNavList list={item.navigationBarItemEntry} display={isHovered} />}
          </div>
        </Link>
        :
        <div onClick={() => onClickItem()}  key={key} style={_styles.itemWrapper}>
          {item.displayName}
          {item.menu && item.menu.length > 0 && <p style={_styles.caret} />}
          {item.navigationBarItemEntry.length > 1 && <SimpleNavList list={item.navigationBarItemEntry} display={isHovered} />}
        </div>
      }
      {item.menu && item.menu.length > 0 && <MegaMenu data={item.menu} display={isHovered} />}
    </div>
  ) : item.__typename === 'navigationBarItems_divider_BlockType' ? (
    <div key={key} style={_styles.divider} />
  ) : null
}

const styles = (isHovered) => {
  return {
    itemWrapper: {
      position: 'relative',
      color: isHovered ? '#007589' : '#FFF',
      fontSize: 18,
      fontFamily: `'Gotham A', 'Gotham B'`,
      marginLeft: 25,
      marginRight: 25,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      paddingBottom: 13,
    },
    divider: {
      height: 35,
      width: 2,
      backgroundColor: 'white',
      marginLeft: 25,
      marginRight: 25,
      marginBottom: 13,
    },
    caret: {
      borderStyle: 'solid',
      borderColor: isHovered ? '#007589' : '#FFF',
      borderWidth: '0 2px 2px 0',
      display: 'inline-block',
      padding: 4,
      transform: isHovered ? 'rotate(-135deg)' : 'rotate(45deg)',
      transition: 'transform 0.15s',
      marginTop: isHovered ? 22 : 13,
      marginLeft: 15,
    },
    linkWrapper: {
      textDecoration: 'none',
    }
  }
}

export default withRouter(NavigationBarItem)
