import React, { Component } from 'react'
import { Article as ArticleComp, withSizes } from '@oos-studio/website-common-components'

class Article extends Component {

  mapDataToProps = (data) => {
    return data.body.map(b => {
      const text = b.__typename === 'body_quote_BlockType' ? b.text : b.bodyText || null
      let typeHandle
      switch(b.__typename) {
        case "body_quote_BlockType":
          typeHandle = 'quote'
          break
        case "body_asset_BlockType":
          typeHandle = 'asset'
          break
        default:
          typeHandle = 'text'
          break
      }
      return {
        typeHandle: typeHandle,
        text: text,
        asset: b.asset,
        caption: b.caption,
        position: b.position,
        autoplay: b.autoplay,
        loop: b.loop,
        controls: b.controls,
      }
    })
  }

  render() {
    const { data, getStyle } = this.props
    const { mapDataToProps } = this

    const _data = {
      ...data,
      dropCap: true,
      newsPostBody: mapDataToProps(data)
    }

    return <ArticleComp data={_data} styles={getStyle(styles)}/>
  }
}

const styles = {
  container: {
    borderTopWidth: 0,
    marginBottom: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  paragraph:{
    container: {
      maxWidth: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  supplement: {
    content: {
      imgCaption: {
        color: '#007589',
        fontFamily: '"Gotham A", "Gotham B"',
        fontWeight: 500,
        fontSize: 15,
        paddingRight: 0,
        paddingLeft: 0,
      },
      imgWrapper: {
        marginTop: 30,
        marginBottom: 30,
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      quote: {
        color: '#6AE1C6',
        fontFamily: '"Gotham A", "Gotham B"',
        fontSize: 48,
        fontStyle: 'unset',
        fontWeight: 300,
        marginTop: 30,
        marginBottom: 30,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  },
  sm: {
    container: {
      marginLeft: 0,
      marginRight: 0,
    },
    paragraph: {
      container: {
        marginRight: 35,
        marginLeft: 35,
        textAlign: 'left',
      }
    },
    supplement: {
      content: {
        imgCaption: {
          fontSize: 13,
          paddingLeft: 35,
          paddingRight: 35,
        },
        quote: {
          fontSize: 38,
          width: 'auto',
          marginLeft: 35,
          marginRight: 35,
        },
      },
    },
  },
}

export default withSizes(Article)
