const addTagClass = (text, tag, _class) => {
  let tmpText = text

  const fullTag = `<${tag}`
  const reTag = new RegExp(fullTag, "g")

  tmpText = tmpText.replace(reTag, `<${tag} class='${_class}'`)

  return tmpText
}

export const addHighlights = (text, isDarkTheme) => {

  const highlightOpenExp = new RegExp('{h}', "g")
  const highlightCloseExp = new RegExp('{/h}', "g")

  text = isDarkTheme ? text.replace(highlightOpenExp, '<mark class="highlight-dark">') : text.replace(highlightOpenExp, '<mark class="highlight">')
  text = text.replace(highlightCloseExp, '</mark>')

  return text
}

export const setStyleClasses = (text, highlight = false, isDarkTheme = false) => {
  let tmpText
  tmpText = addTagClass(text, 'ol', 'articleOl')
  tmpText = addTagClass(tmpText, 'ul', 'articleUl')
  tmpText = addTagClass(tmpText, 'a', 'articleAnchor')

  tmpText = addHighlights(tmpText, isDarkTheme)


  return tmpText
}
