import React, { Component } from 'react'
import { Header, withSizes } from '@oos-studio/website-common-components'
import Article from './Article'
import ArticleDetails from './ArticleDetails'
import ContactUs from '../contactUs/ContactUs'
import { gqlConnect } from 'react-connect-graphql-beta'
import { setNavbarBackground } from '../navigation/actions'
import { connect } from 'react-redux'
import EmployeeBio from '../aboutUs/employeeBio/EmployeeBio'
import { withSplash } from '../hocs/withSplash'
import DocumentMeta from '../../common/DocumentMeta'

class ArticlePage extends Component {
  componentDidMount() {
    const { setNavbarBackground } = this.props
    setNavbarBackground(true)

    const element = document.querySelector('#paragraph p')
    if(element) {
      element.classList.add('dropCap')
    }
  }

  componentWillUnmount() {
    const { setNavbarBackground } = this.props
    setNavbarBackground(false)
  }

  getHeader = () => {
    const { entry } = this.props.gql
    const { sm } = this.props

    return {
      backgroundAsset: entry.heroImage[0],
      styles: sm ? {
        container: {
          height: 300,
        },
        backgroundAsset: {
          height: 300,
          objectPosition: entry.headerAlignment,
        },
      } : {
        container: {
          height: 500,
        },
        backgroundAsset: {
          zIndex: -1,
        },
      },
    }
  }

  render() {
    const { entry } = this.props.gql
    const { getStyle } = this.props
    const { getHeader } = this

    const meta = {
      title: entry.title,
      description: entry.articleDescription ? entry.articleDescription : '',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: entry.articleKeywords ? entry.articleKeywords : '',
          author: entry.author.name,
        },
        itemProp: {
          name: entry.title,
          description: entry.articleDescription ? entry.articleDescription : '',
          image: entry.heroImage[0].url
        },
        property: {
          'og:title': entry.title,
          'og:type': 'article',
          'og:image': entry.heroImage[0].url,
          'og:site_name': 'oos',
          'og:description': entry.articleDescription ? entry.articleDescription : '',
          'og:url': window.location.href,
          'fb:app_id': '2317018421941218',
        },
      },
      auto: {
        ograph: true
      }
    }

    console.log(meta)

    return(
      <div className={'articlePage'}>
        <DocumentMeta {...meta}>
          <Header {...getHeader()}/>
          <div style={styles.content}>
            <ArticleDetails data={entry}/>
            <div style={styles.articleWrapper}>
              <Article data={entry} />
            </div>
            <div style={getStyle(styles.dividerContainer)}><div style={styles.divider}></div></div>
            <EmployeeBio image={entry.author.userPhoto[0].url} body={entry.author.bio} styles={styles.bio}/>
            <ContactUs />
          </div>
        </DocumentMeta>
      </div>
    )
  }
}


const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 0,
    backgroundColor: 'white',
    position: 'relative',
    overflow: 'hidden',
  },
  dividerContainer: {
    width: '100%',
    paddingLeft: 50,
    paddingRight: 50,
    height: 1,
    sm: {
      paddingLeft: 35,
      paddingRight: 35,
    },
  },
  divider: {
    backgroundColor: '#4D4E56',
    height: 1,
    opacity: 0.5,
    width: '100%',
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  articleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bio: {
    container: {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
      alignItems: 'center',
      lineHeight: 1.6,
      maxWidth: 800,
    },
    infoWrapper: {
      paddingLeft: 0,
    },
    body: {
      paddingTop: 0,
      paddingBottom: 40,
      paddingLeft: 0,
    },
    employeeBio: {
      textAlign: 'center',
      paddingTop: 60,
      position: 'relative',
      listStyle: 'none',
      paddingBottom: 75,
      marginLeft: 50,
      marginRight: 50,
      lineHeight: 2,
    }
  },
}

const mapDispatchToProps = dispatch => {
  return {
    setNavbarBackground: show => dispatch(setNavbarBackground(show)),
  }
}

export default gqlConnect('post')(connect(null, mapDispatchToProps)(withSplash(withSizes(ArticlePage))))
