import React from 'react'
import SplashScreen from '../splashScreen/SplashScreen'

export const withSplash = (Component, fadeTime = 0.3) => {
  return class extends React.Component {
    static onLoadingSelf = true

    state = {
      opacity: 0,
      transition: ''
    }

    componentDidMount() {
      this.setState({
        opacity: this.props.loading ? 1 : 0,
      })
    }

    componentDidUpdate(prevProps) {
      const { loading } = this.props
      if (prevProps.loading && !loading) {
        this.setState({
          opacity: 0,
          transition: `opacity ${fadeTime}s`
        })
        setTimeout(() => {
          this.setState({
            display: 'none'
          })
        }, fadeTime * 1000)
      }
    }

    render() {
      const { loading } = this.props

      return (
        <React.Fragment>
          { loading ? null : <Component {...this.props} /> }
          <SplashScreen style={this.state}/>
        </React.Fragment>
      )
    }
  }
}
