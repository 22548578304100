import $ from 'jquery'

let animating = false

export const scrollToContactForm = (history) => {
  let interval

  let contactForm = document.getElementById('contact-us-form')

  if(!contactForm) {
    history.push('/')
    setTimeout(() => {
      contactForm = document.getElementById('contact-us-form')
    }, 100)
    interval = setInterval(function() {
      if (contactForm != null) {
        if (contactForm.offsetTop > 0) {
          scrollToElement(contactForm)
          stopScrollToElement(interval)
        }
      }
    }, 100)
  } else {
    scrollToElement(contactForm)
  }
}

const scrollToElement = (element) => {
  let container = $([document.documentElement, document.body])

  if (element) {
    animating = true
    container.animate({
      scrollTop: element.getBoundingClientRect().top + document.documentElement.scrollTop,
    }, 1000, function() {
      animating = false
    })
  }
}

const stopScrollToElement = (interval) => {
  clearInterval(interval)
}
