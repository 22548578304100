import $ from 'jquery'

export const SET_NAVBAR_BACKGROUND = 'SET_NAVBAR_BACKGROUND'
export const SET_SHOW_NAVBAR = 'SET_SHOW_NAVBAR'
export const SET_ACTIVE_MEGA_MENU = 'SET_ACTIVE_MEGA_MENU'
export const SET_MEGA_MENU_HOVERED = 'SET_MEGA_MENU_HOVERED'
export const SET_MEGA_MENU_PARENT_HOVERED = 'SET_MEGA_MENU_PARENT_HOVERED'

export const setMegaMenuHovered = isHovered => {
  return {
    type: SET_MEGA_MENU_HOVERED,
    value: isHovered,
  }
}

export const setMegaMenuParentHovered = isHovered => {
  return {
    type: SET_MEGA_MENU_PARENT_HOVERED,
    value: isHovered,
  }
}

export const setNavbarBackground = show => {
  return {
    type: SET_NAVBAR_BACKGROUND,
    value: show,
  }
}

export const setActiveMegaMenu = menu => {
  return {
    type: SET_ACTIVE_MEGA_MENU,
    value: menu,
  }
}

export const setShowNavbar = show => dispatch => {
  let nav = $('#navbar')
  let megaMenus = $('.mega-menu')
  let simpleLists = $('.simple-list')

  let canAnimate = true
  megaMenus.each(function() {
    if($(this).height() > 0) {
      canAnimate = false
    }
  })

  simpleLists.each(function() {
    if($(this).height() > 0) {
      canAnimate = false
    }
  })

  if(show) {
    nav.css("transform", "translateY(0)")
    nav.css("transition", "transform .5s ease-out, opacity 0s 0s")
    nav.css("opacity", 1)
  } else if (canAnimate){
    nav.css("transform", "translateY(-100%)")
    nav.css("transition", "transform .5s ease-out, opacity 0s 0.5s")
  }
  dispatch({type: SET_SHOW_NAVBAR, value: show,})
}
