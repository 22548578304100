import React, { Component } from 'react'
import './EmployeeBio.css'
import SloganItem from "../../home/computerAnimation/ComputerAnimation"

class EmployeeBio extends  Component {
    render() {
        const { styles } = this.props
        return (
            <li style={styles.employeeBio}>
                <div style={styles.container} className={'eb-columns'}>
                    <div id={'eb-image-wrapper'} className={'eb-column'}>
                        <img className={'eb-image'} src={this.props.image}/>
                    </div>
                    <div className={'eb-column'}>
                        <div style={styles.infoWrapper} className={'eb-bio'}>
                            <div className={'eb-header'}>
                                <div className={'eb-name'}>
                                    {this.props.name}
                                </div>
                                <div className={'eb-title'}>
                                    {this.props.title}
                                </div>
                            </div>
                            <div style={styles.body} className={'eb-body'}>
                                {this.props.body}
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        )
    }
}

EmployeeBio.defaultProps = {
    styles: {
        employeeBio: {
            paddingTop: 60,
            position: 'relative',
            listStyle: 'none',
            paddingBottom: 90,
            marginLeft: 50,
            marginRight: 50,
            lineHeight: 2,
            textAlign: 'left',
        }
    },
}

export default EmployeeBio
