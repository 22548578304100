import React from 'react'
import {default as ReactDocMeta} from 'react-document-meta'

const DocumentMeta = (props) => {
  const finalProps = {
    ...props,
    title: `oos | ${props.title}`
  }
  return <ReactDocMeta {...finalProps} />
}

export default DocumentMeta
