import axios from 'axios'
import config from '../../config'

export const setName = name => ({
  type: 'SET_NAME',
  payload: name,
})

export const setEmail = email => ({
  type: 'SET_EMAIL',
  payload: email,
})

export const setPhoneNumber = phone => ({
  type: 'SET_PHONE_NUMBER',
  payload: phone,
})

export const setCompany = company => ({
  type: 'SET_COMPANY',
  payload: company,
})

export const setMessage = message => ({
  type: 'SET_MESSAGE',
  payload: message,
})

const setSending = isSending => ({
  type: 'SET_SENDING',
  payload: isSending,
})

export const setStatusMessage = message => ({
  type: 'SET_STATUS_MESSAGE',
  payload: message,
})

function gtag_report_conversion() {
  window.gtag('event', 'conversion', {
    'send_to': 'AW-448171304/XCA9CKzuv_cBEKia2tUB',
  });
  return false;
}

export const submitInquiry = (inquiry) => {
  return async dispatch => {
    dispatch(setSending(true))
    console.log(inquiry)
    const inquiryRes = await axios.post(`${config.oosApiBaseUrl}/inquiry/submit`, {
        name: inquiry.name,
        email: inquiry.email,
        phone: inquiry.phone,
        message: inquiry.message,
        company: inquiry.company,
      })

    if(inquiryRes.data === 'success') {
      gtag_report_conversion(window.location.href)
      dispatch(setStatusMessage('Thanks for the message! We’ll be in touch soon.'))
    } else {
      dispatch(setStatusMessage("Oh deer, your moose-age could not be sent. To avoid further puns, please try again."))
    }

    dispatch(setSending(false))
  }
}
