import React, { Component } from 'react'
import ModularPage from '../common/ModularPage'
import { gqlConnect } from 'react-connect-graphql-beta'
import { withSplash } from '../hocs/withSplash'
import { withSizes } from '@oos-studio/website-common-components'

class SolutionsPage extends Component {
  render() {
    return <ModularPage gql={this.props.gql} />
  }
}

export default gqlConnect('solutions')(withSplash(withSizes(SolutionsPage)))
