import React, { Component } from 'react'
import { Controller, Scene } from 'react-scrollmagic'
import {Timeline, Tween} from 'react-gsap'
import { withSizes } from '@oos-studio/website-common-components'

class OurApproach extends Component {
  constructor(props) {
    super(props)

    this.state = {
      offset: window.innerHeight / 2,
      duration: window.innerHeight * (props.states.length - 1),
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize)
  }

  onResize = () => {
    const { states } = this.props

    this.setState({
      offset: window.innerHeight / 2,
      duration: window.innerHeight * (states.length - 1),
    })
  }

  getStateSection = (state, index) => {
    const { getStyle, lg } = this.props
    let float = index % 2 === 0 ? 'right' : 'left'
    if (lg) {
      float = 'right'
    }
    return (
      <div style={getStyle(styles.stateSection)}>

        <div style={{...getStyle(styles.stateCopy), float, }}>
          <div style={getStyle(styles.stateHeader)}>
            {state.heading}
          </div>
          <div style={getStyle(styles.stateBody)}>
            {state.body}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { heading, states, getStyle } = this.props

    const pinImage = document.getElementById('pinImage')
    let pinImageWidth = 0
    if(pinImage) {
      pinImageWidth = pinImage.getBoundingClientRect().width
    }

    return (
      <div style={getStyle(styles.container)}>
        <Controller>
          <Scene offset={window.innerHeight / 2} duration={window.innerHeight * (states.length - 1)} pin>
          <div id={'trigger'} style={{ position: 'absolute', width: '100%', height: '100vh'}}>
            <div style={getStyle(styles.header)}>
              <div style={getStyle(styles.headerSpacer)}>
                <span style={getStyle(styles.heading)}>{heading}</span>
              </div>
            </div>
            <div style={getStyle(styles.imageContainer)}>
              <div style={getStyle(styles.imageWrapper)}>
                <Controller>
                  <Scene triggerElement={'#trigger'} offset={window.innerHeight / 2} duration={window.innerHeight * (states.length)}>
                    {(progress) => (
                      <Tween to={{y: -100}} totalProgress={progress} paused>
                        {states.map((state, index) => {
                          const period = 1 / states.length
                          const halfPeriod = period / 2
                          const startOut = halfPeriod + (period * index)
                          const endOut = startOut + (halfPeriod / 2)
                          const startIn = halfPeriod / 2 + (period * (index - 1))
                          const endIn = startIn + (halfPeriod / 2)
                          const fadeInProgress = (progress - startIn) / (endIn - startIn)
                          const fadeOutProgress = (progress - startOut) / (endOut - startOut)

                          return (
                            <div style={{position: 'relative', zIndex: index }}>
                              <Timeline target={
                                <img id={'pinImage'} style={{...getStyle(styles.image), left: `calc(50% - ${pinImageWidth / 2}px`}} src={state.image[0].url}/>
                              }>
                                <Tween from={{ opacity: 1}} to={{ opacity: 0 }} totalProgress={fadeOutProgress} paused/>
                              <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} totalProgress={fadeInProgress} paused/>

                              </Timeline>
                            </div>
                          )
                        })}
                      </Tween>
                    )}
                  </Scene>
                </Controller>
              </div>
            </div>
          </div>
          </Scene>
        </Controller>
        <div style={getStyle(styles.content)}>
          {states.map((state, index) => this.getStateSection(state, index))}
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    backgroundColor: '#007589',
  },
  spacer: {
    display: 'inline-block',
    backgroundColor: 'red',
  },
  content: {
    maxWidth: 1280,
    margin: 'auto',
  },
  header: {
    paddingTop: 75,
    paddingBottom: 75,
    maxWidth: 475,
    margin: 'auto',
    paddingLeft: 48,
    paddingRight: 48,
    xxs: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  headerSpacer: {
    width: '100%',
    textAlign: 'center',
    borderBottom: '1px solid #6AE1C6',
    lineHeight: '0.1em',
    margin: '10px 0 20px',
  },
  heading: {
    display: 'inline',
    fontSize: 18,
    textTransform: 'uppercase',
    color: '#6AE1C6',
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: '#007589',
    fontFamily: 'Gotham A, Gotham B',
    letterSpacing: 3,
    xs: {
      fontSize: 15,
    },
    xxs: {
      paddingLeft: 10,
      fontSize: 12,
      paddingRight: 10,
    },
  },
  stateSection: {
    height: '100vh',
  },
  stateCopy: {
    width: 368,
    paddingLeft: 48,
    paddingRight: 48,
    marginTop: '50vh',
    transform: 'translateY(-50%)',
    marginRight: 'auto',
    lg: {
      marginRight: '2%',
      width: '40%',
    },
    sm: {
      width: '100%',
      marginRight: 0,
      textShadow: '2px 1px #3B3B3B',
    },
    xxs: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  stateHeader: {
    fontFamily: 'Gotham Cond A, Gotham Cond B',
    letterSpacing: 2.25,
    fontSize: 40,
    lineHeight: 1.125,
    textTransform: 'uppercase',
    color: '#6AE1C6',
    marginBottom: 15,
    textShadow: 'none',
    xs: {
      fontSize: 35,
    },
  },
  stateBody: {
    fontSize: 18,
    fontWeight: 300,
    fontFamily: 'Gotham A, Gotham B',
    color: 'white',
    xs: {
      fontSize: 16,
    },
  },
  imageContainer: {
    maxWidth: 625,
    margin: 'auto',
    height: 'calc(100% - 182px)',
    position: 'relative',
    marginLeft: 'auto',
    maxHeight: 'none',
    lg: {
      marginLeft: '5%',
      maxWidth: '55%',
      maxHeight: 'none',
    },
    sm: {
      width: 'auto',
      maxWidth: 'none',
      marginLeft: 'auto',
      height: '60vh',
    },
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '50%',
    sm: {
    },
  },
  image: {
    width: '100%',
    height: 'auto',
    transform: 'translateY(-50%)',
    position: 'absolute',
    sm: {
      maxWidth: 475,
      maxHeight: 475,
    },
  }
}

export default withSizes(OurApproach)
