import React, { Component } from 'react'
import { Route, withRouter, Switch } from 'react-router-dom'
import { gqlConnect } from 'react-connect-graphql-beta'
import { getPage } from '../../utils/ComponentFactory'

import PrivacyPolicy from '../privacyPolicy/PrivacyPolicy'
import NotFound from '../notFound/NotFound'
import Home from '../home/Home'
import AboutUs from '../aboutUs/AboutUs'
import ArticlePage from '../article/ArticlePage'
import SolutionsPage from '../solutions/SolutionsPage'
import ServicesPage from '../services/ServicesPage'

class Routes extends Component {
  state = {
    routes: [],
  }

  getRoutes = () => {
    const { routes } = this.props.gql
    return routes.map((route, index) => {
      return (
        <Route key={index} exact path={`/${route.slug}`} component={getPage(route.type)} />
      )
    })
  }

  componentDidUpdate(prevProps) {
    const { loading } = this.props
    if (!loading && loading !== prevProps.loading) {
      this.setState({
        routes: this.getRoutes(),
      })
    }
  }

  componentDidMount() {
    const { loading } = this.props
    if (!loading) {
      this.setState({
        routes: this.getRoutes(),
      })
    }
  }

  render() {
    const { routes } = this.state
    return (
      <Switch>
        <Route exact path={'/'} component={Home}/>
        <Route exact path={'/about'} component={AboutUs}/>
        <Route exact path={'/privacy_policy'} component={PrivacyPolicy}/>
        {routes}
        <Route exact path={'/articles/*'} component={() => <ArticlePage key={window.location.pathname} />}/>
        <Route exact path={'/solutions/*'} component={() => <SolutionsPage key={window.location.pathname} />}/>
        <Route exact path={'/services/*'} component={() => <ServicesPage key={window.location.pathname} />}/>
        <Route path={'*'} component={NotFound}/>
      </Switch>
    )
  }
}

export default gqlConnect('routes')(
  withRouter(Routes),
)
