import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import MegaMenuItem from './MegaMenuItem'
import AnimateHeight from 'react-animate-height'

const MegaMenu = ({ data, display }) => {
  const menu = data[0]
  const _styles = styles(display)

  return (
    <AnimateHeight id={'mega-menu-mobile'} height={display ? 'auto' : 0} duration={250} style={_styles.animateHeightWrapper}>
      <div style={_styles.container}>
        {menu.item.map((item, index) => {
          return <MegaMenuItem item={item} index={index} />
        })}
      </div>
    </AnimateHeight>
  )
}

const styles = (display) => {
  return {
    animateHeightWrapper: {
      marginTop: -10,
    },
    container: {
      paddingRight: display ? 25 : 0,
      paddingLeft: display ? 25 : 0,
      paddingBottom: 0,
      paddingTop: 0,
      marginTop: display ? 10 : 0,
      width: '100%',
      // height: height,
      transition: 'margin-top 0.25s',
      backgroundColor: 'rgba(255,255,255,0.85)',
      backdropFilter: 'blur(50px)',
      '-webkit-backdrop-filter': 'blur(50px)',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }
}

export default withRouter(MegaMenu)
