import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './redactor.css'
import App from './components/app/App'
import 'bootstrap/dist/css/bootstrap.css'
import 'react-modal-video/scss/modal-video.scss'
import 'scrollmagic/scrollmagic/uncompressed/ScrollMagic'
import GQLProvider from 'react-connect-graphql-beta'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import request from 'request'
import SplashScreen from './components/splashScreen/SplashScreen'
import _gql from './_gql'
import introspectionQueryResultData from './fragmentTypes.json'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './redux/index'
import config from './config'

request(`${window.location.origin}/config.json`, { json: true }, (err, res) => {
  const { uri, token, oosApiBaseUrl } = res.body

  config.oosApiBaseUrl = oosApiBaseUrl

  // Apollo graphQL API Connection Stuff
  const httpLink = createHttpLink({ uri })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  })

  // instance of _wapiti store controller
  const store = GQLProvider.createStore({
    link: authLink.concat(httpLink),
    gql: _gql,
    introspectionFragmentMatcherOptions: {
      introspectionQueryResultData
    },
  })

  const reduxStore = createStore(rootReducer, applyMiddleware(thunkMiddleware))


  // Delay ReactDOM render by 1 millisecond to allow loading gif to begin animating in Safari
  setTimeout(() => {
    ReactDOM.render(<Provider store={reduxStore}><GQLProvider store={store} fallback={SplashScreen} fadeInOnce={true} fallbackOnce={true}><App /></GQLProvider></Provider>, document.getElementById('root'))
  }, 50)
})

