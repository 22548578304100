import React, { Component } from 'react'
import './Header.css'

class Header extends  Component {
    render() {
        return (
            <div className={"header"} style={this.props.style}>
                <div className={"header-text"}>
                    <span>{this.props.title}</span>
                    <div className={"header-caption"}>
                        <span>{this.props.caption}</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header