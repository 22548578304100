const initialState = {
  name: '',
  email: '',
  phoneNumber: '',
  company: '',
  message: '',
  isSending: false,
  error: null,
  statusMessage: '',
}

const contactUs = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NAME':
      return Object.assign({}, state, { name: action.payload })
    case 'SET_EMAIL':
      return Object.assign({}, state, { email: action.payload })
    case 'SET_COMPANY':
      return Object.assign({}, state, { company: action.payload })
    case 'SET_PHONE_NUMBER':
      return Object.assign({}, state, { phoneNumber: action.payload })
    case 'SET_MESSAGE':
      return Object.assign({}, state, { message: action.payload })
    case 'SET_SENDING':
      return Object.assign({}, state, { isSending: action.payload })
    case 'SET_ERROR':
      return Object.assign({}, state, { error: action.payload })
    case 'SET_STATUS_MESSAGE':
      return Object.assign({}, state, { statusMessage: action.payload })
    default:
      return state
  }
}

export default contactUs
