import { SET_NAVBAR_BACKGROUND, SET_ACTIVE_MEGA_MENU, SET_MEGA_MENU_HOVERED, SET_MEGA_MENU_PARENT_HOVERED } from './actions'

const initialState = {
  showNavbarBackground: false,
  activeMegaMenu: null,
  isMegaMenuParentHovered: false,
  isMegaMenuHovered: false,
}
const navigation = (state = initialState, action) => {
  switch(action.type) {
    case SET_NAVBAR_BACKGROUND:
      return {...state, showNavbarBackground: action.value}
    case SET_ACTIVE_MEGA_MENU:
      return {...state, activeMegaMenu: action.value}
    case SET_MEGA_MENU_HOVERED:
      return {...state, isMegaMenuHovered: action.value}
    case SET_MEGA_MENU_PARENT_HOVERED:
      return {...state, isMegaMenuParentHovered: action.value}
    default:
      return state
  }
}


export default navigation
