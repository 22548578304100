import React, { useState } from 'react'
import { withRouter, Link } from 'react-router-dom'

const MegaMenuItem = ({ item, index, numItems }) => {
  const [hoveringButton, setHoveringButton] = useState(false)

  const _styles = styles(index, hoveringButton, numItems)

  const onClickButton = (e) => {
    if (item.itemUrl) {
      e.preventDefault()
      window.open(item.itemUrl, '__blank')
    }
  }

  let url = ''
  if (item.itemEntry.length) {
    url = `/${item.itemEntry[0].uri}`
  }
  
  return (
    <div style={{ display: 'flex', height: '100%' }}>
      {(((numItems === 2 || numItems === 4) && index % 2 === 1) || (numItems !== 2 && numItems !== 4 && index % 3 === 1)) && <div style={_styles.borderSide} />}
      <div key={index} style={_styles.container}>
        <img src={item.icon[0].url} style={_styles.icon} />
        <div style={_styles.title}>{item.heading}</div>
        <div style={_styles.text}>{item.blurb}</div>
        <Link
          to={url}
          onClick={(e) => onClickButton(e)}
          onMouseEnter={() => setHoveringButton(true)}
          onMouseLeave={() => setHoveringButton(false)}
          style={_styles.button}>
          {item.buttonText || 'Learn More'}
          <p style={_styles.caret} />
        </Link>
      </div>
      {numItems !== 2 && numItems !== 4 && index % 3 === 1 && <div style={_styles.borderSide} />}
    </div>
  )
}

const styles = (index, hoveringButton, numItems) => {
  return {
    container: {
      paddingLeft: 60,
      paddingRight: 60,
      paddingTop: 15,
      paddingBottom: 25,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      // borderLeft: index % 3 === 1 ? '1px solid #C9C9C7' : '',
      // borderRight: index % 3 === 1 ? '1px solid #C9C9C7' : '',
      //  borderBottom: numItems - index > 3  ? '1px solid #C9C9C7' : '',
    },
    icon: {
      height: 75,
      width: 75,
      objectFit: 'cover',
    },
    borderSide: {
      height: 'calc(100% - 75px)',
      alignSelf: 'center',
      width: 1,
      backgroundColor: '#C9C9C7',
    },
    title: {
      marginBottom: 20,
      color: '#4D4E56',
      fontSize: 25,
      fontFamily: "'Gotham A', 'Gotham B'",
      fontWeight: 300,
      textAlign: 'center',
      lineHeight: 1.25,
    },
    text: {
      color: '#707073',
      fontSize: 14,
      fontFamily: "'Gotham A', 'Gotham B'",
      fontWeight: 400,
      textAlign: 'center',
      marginBottom: 20,
    },
    button: {
      marginTop: 'auto',
      position: 'relative',
      color: hoveringButton ? '#7FBAC4' : '#007589',
      fontSize: 14,
      textTransform: 'uppercase',
      fontFamily: "'Gotham A', 'Gotham B'",
      fontWeight: 500,
      letterSpacing: 1.6,
      cursor: 'pointer',
      textDecoration: 'none',
    },
    caret: {
      borderStyle: 'solid',
      borderColor: hoveringButton ? '#7FBAC4' : '#007589',
      borderWidth: '0 2px 2px 0',
      display: 'inline-block',
      padding: 4,
      transform: 'rotate(-45deg)',
      position: 'absolute',
      left: hoveringButton ? 'calc(100% + 15px)' : 'calc(100% + 5px)',
      top: 4,
      transition: 'left 0.25s',
    },
  }
}

export default withRouter(MegaMenuItem)
