import React from 'react'
import '../../../redactor.css'
import {setStyleClasses} from '../../../utils/HTMLTags'
import MegaMenuItem from './MegaMenuItem'
import AnimateHeight from 'react-animate-height'

const MegaMenu = ({ data, display }) => {
  const _styles = styles(display)

  const menu = data[0]

  return (
      <AnimateHeight className={'mega-menu'} height={display ? 'auto' : 0} style={{..._styles.container, pointerEvents: display ? 'auto' : 'none'}}>
        <div style={_styles.content}>
          <div className={'content-block'} style={_styles.heading}>
            <div style={_styles.html} dangerouslySetInnerHTML={{ __html: setStyleClasses(menu.blurb, true, false) }} />
          </div>
          <div style={{..._styles.grid, gridTemplateColumns: menu.item.length === 2 || menu.item.length === 4 ? '1fr 1fr' : '1fr 1fr 1fr'}}>
            {
              menu.item.map((item, index) => {
                return (
                  <div style={{position: 'relative', height: '100%'}}>
                    { index < 3 && menu.item.length > 3 && <div style={{
                      position: 'absolute',
                      top: 0,
                      left: 35,
                      bottom: 0,
                      right: 35,
                      borderBottom: 'solid 1px #C9C9C7',
                    }}/>}
                    <MegaMenuItem index={index} item={item} numItems={menu.item.length} />
                  </div>
                )
              })
            }
          </div>
        </div>
      </AnimateHeight>
  )
}

const styles = (display) => {
  return {
    container: {
      position: 'absolute',
      right: 0,
      top: 65,
      left: 0,
      backgroundColor: 'rgba(255,255,255,0.85)',
      transition: 'opacity 0.15s',
      opacity: display ? 1 : 0,
      overflow: 'hidden',
      justifyContent: 'center',
      borderBottom: 'solid #C9C9C7 1px',
      backdropFilter: 'blur(50px)',
      '-webkit-backdrop-filter': 'blur(50px)',
    },
    content: {
      width: 1200,
      height: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: 35,
    },
    heading: {
      width: '100%',
      padding: 20,
      paddingTop: 0,
    },
    html: {
      textAlign: 'center',
    },
    grid: {
      position: 'relative',
      display: 'grid',
    },
  }
}

export default MegaMenu
