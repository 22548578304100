import React, {Component} from 'react';
import './SloganItem.css'

class SloganItem extends Component {
    render() {
        return (
            <li className={'slogan-list-item'}>
                <article className={'slogan-item'}>
                    <div>
                        <div className={'slogan-item-heading'}>
                            {this.props.heading}
                        </div>
                        <div className={'slogan-item-text'}>
                            {this.props.text}
                        </div>
                    </div>
                </article>
            </li>
        )
    }
}

export default SloganItem