import React, { Component } from 'react'
import './AboutUs.css'
import Header from '../globals/header/Header'
import ContactUs from '../contactUs/ContactUs'
import EmployeeBio from './employeeBio/EmployeeBio'
import { gqlConnect } from 'react-connect-graphql-beta'
import { withSplash } from '../hocs/withSplash'
import {
  getMeta,
  _title,
  _description,
  _image,
  parseKeywords,
  _keywords,
} from '../../common/defaultMeta'
import DocumentMeta from '../../common/DocumentMeta'

class AboutUs extends  Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { aboutTitle, aboutCaption, aboutDescription, aboutEmployees, seoImage, seoDescription, seoKeywords } = this.props.gql

        return (
            <div className={'about-us'}>
              <DocumentMeta {...getMeta(
                _title,
                seoDescription || _description,
                seoImage.length > 0 ? seoImage[0].url : _image,
                seoKeywords.length > 0
                  ? parseKeywords(seoKeywords)
                  : _keywords,
              )}>                <Header title={aboutTitle}
                        caption={aboutCaption}/>
                <div className={'about-us-section'}>
                  <div className={'about-us-content'}>
                    <div className={'about-us-description'}>
                      {aboutDescription}
                    </div>
                    <ul className={'about-us-bios'}>
                      {aboutEmployees.map((item, key) => {
                        const {employeeName, employeeTitle, employeeBio, employeePicture} = item
                        return (
                          <EmployeeBio name={employeeName}
                                       title={employeeTitle}
                                       body={employeeBio}
                                       image={employeePicture[0].url}
                                       key={key}/>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                <ContactUs/>
              </DocumentMeta>
            </div>
        )
    }
}

export default gqlConnect('about')(withSplash(AboutUs))
