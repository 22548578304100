import React, { Component } from 'react'
import './SplashScreen.css'
import loadingAnimation from './images/loadingAnimation.gif'

class SplashScreen extends Component {
    render() {
        return (
            <div className={'splash-screen'} {...this.props}>
                <img className={'loading-animation'} src={loadingAnimation}/>
            </div>
        )
    }
}

export default SplashScreen
