import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import {isMobile} from "mobile-device-detect"
import Footer from "../footer/Footer"
import NavigationBarSelector from '../navigation/NavigationBarSelector'
import Routes from '../common/Routes'

class App extends Component {
    portraitVH = 0
    landscapeVH = 0

    componentDidMount() {
        if (isMobile) {
            let that = this
            setTimeout(function() {
                let vh = window.innerHeight * 0.01;
                if (window.innerHeight >= window.innerWidth) {
                    that.portraitVH = vh
                } else {
                    that.landscapeVH = vh
                }
                document.documentElement.style.setProperty('--vh', `${vh}px`)
                window.addEventListener('resize', that.onResize)
            })
        }
    }

    onResize = () => {
        let vh = window.innerHeight * 0.01
        if (window.innerHeight >= window.innerWidth) {
            if (this.portraitVH > vh) {
                this.portraitVH = vh
            } else {
                if (this.portraitVH === 0) {
                    this.portraitVH = vh
                }
                vh = this.portraitVH
            }
        } else {
            if (this.landscapeVH > vh) {
                this.landscapeVH = vh
            } else {
                if (this.landscapeVH === 0) {
                    this.landscapeVH = vh
                }
                vh = this.landscapeVH
            }
        }
        document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    render () {
        return (
            <Router>
                <div className="App">
                    <NavigationBarSelector />
                    <Footer/>
                    <div className={'footer-bottom-margin-element'}>
                        <Routes/>
                    </div>
                </div>
            </Router>
        )
    }
}

export default App;
