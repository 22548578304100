const footer = res => {
    return {
        data: res.data.globalSets.filter(set => {
            return set.__typename === 'footer_GlobalSet'
        })
    }
}

const logos = res => {
    return {
        data: res.data.globalSets.filter(set => {
            return set.__typename === 'logos_GlobalSet'
        })
    }
}

const page = res => {
    return res.data.entries[0]
}

const entries = res => {
    return res.data.entries
}

const categories = res => {
    return {
        categories: res.data.categories
    }
}

const entryFeed = res => {
    return res.data.entries.map(entry => {
        const postDate = new Date(entry.postDate)
        return {
            title: entry.title,
            imageUrl: entry.heroImage[0] ? entry.heroImage[0].url : '',
            date: postDate.getMonth() + 1 + '/' + postDate.getDate() + '/' + postDate.getFullYear(),
            uri: entry.uri,
        }
    })
}

const article = res => {
    return {
        entry: res.data.entries[0]
    }
}

const solutions = res => {
    return {
        entry: res.data.entries[0],
    }
}

const navbarDropdowns = res => {
    return {
        data: res.data.entries,
    }
}

const navigationBar = res => {
    return {
        data: res.data.globalSets,
    }
}

const consulting = res => {
    return {
        entry: res.data.entries[0],
    }
}

export default [
    {
        id: 'logos',
        mapToProps: logos,
        query: `{
            globalSets {
                ... on logos_GlobalSet {
                    portraitLogoOverPrimary {
                        id
                        url
                    }
                    landscapeLogoOverPrimary {
                        id
                        url
                    }
                    iconOverDark {
                        id
                        url
                    }
                }
            }
        }`,
    },
    {
        id: 'footer',
        mapToProps: footer,
        query: `{
            globalSets {
                ... on footer_GlobalSet {
                    description
                    motto
                    contactBlock {
                        ... on contactBlock_contactType_BlockType {
                            contactTypeDisplay
                            contactValue
                            contactType
                        }
                    }
                    socialBlock {
                        ... on socialBlock_socialLink_BlockType {
                            socialIcon {
                                id
                                url
                            }
                            socialLink
                        }
                    }
                }
            }
        }`,
    },
    {
        id: 'home',
        mapToProps: page,
        query: `{
            entries(section:["home"]) {
                ...on home_home_Entry {
                seoImage {
                    url
                }
                seoKeywords{
                  ... on seoKeywords_keyword_BlockType{
                    keywordTitle,
                  }
                }
                seoDescription
                    valueProposition,
                  heading,
                  services{
                    ... on services_service_BlockType{
                      serviceName,
                      serviceBody,
                      serviceUrl,
                      buttonText,
                      entryLink{
                        uri,
                        slug,
                      },
                      serviceImage{
                        url,
                      }
                    }
                  }
                  serviceBackgroundImage {
                    url
                  }
                  serviceHeading
                  servicesNew {
                    ...on servicesNew_service_BlockType{
                      icon {
                        url
                      }
                      serviceTitle
                      heading1
                      heading2
                      heading3
                      subheading1
                      subheading2
                      subheading3
                      entryLink{
                        uri,
                        slug,
                      },
                    }
                  }
                  customersHeading,
                    gridStyle,
                    customers{
                    ... on customers_customer_BlockType{
                      customerUrl,
                      customerName,
                      customerImage{
                        url,
                      }
                      entryLink{
                        slug,
                        uri,
                      }
                    }
                  }
                    headline,
                    headlineNew,
                  parallaxDivider,
                    ourApproachStates {
                      ... on ourApproachStates_ourApproachState_BlockType {
                        heading,
                        body,
                        image {
                          url
                        },
                      }
                    }
                    ourApproachHeading
                  homepageSolutionsHeading,
                  homepageSolutions{
                    ... on homepageSolutions_homepageSolution_BlockType{
                      solutionName,
                      solutionUrl,
                      solutionIcon{
                        url,
                      },
                      entryLink{
                        slug,
                        uri,
                      },
                    }
                  }
                }
            }
        }`,
    },
    {
        id: 'about',
        mapToProps: page,
        query: ` {
            entries(section:["about"]) {
              ...on about_about_Entry {
                seoImage {
                    url
                }
                seoDescription
                seoKeywords{
                  ... on seoKeywords_keyword_BlockType{
                    keywordTitle,
                  }
                }
                aboutTitle
                aboutCaption
                aboutDescription
                aboutEmployees {
                    ... on aboutEmployees_employee_BlockType {
                    employeeName
                    employeeTitle
                    employeeBio
                    employeePicture {
                      id
                      url
                    }
                  }
                }
             }
            }
        }`
    },
    {
        id: 'app',
        query: `{
            
        }`
    },
    {
        id: "contactForm",
        mapToProps: entries,
        query: `{
          entries(section:["contactForm"]) {
            id
          }
        }`,
    },
    {
        id: 'topics',
        mapToProps: entries,
        query: `{
                entries(section:topics) {
              ...on Topics {
                title,
                author {
                  id,
                  name,
                },
                dateCreated,
                dateUpdated,
                tags,
                url,
                uri,
                slug
              }
            }
        }`
    },
    {
        id: 'articleFeed',
        mapToProps: entryFeed,
        query: `query getArticles($limit:Int, $offset:Int, $search:String) {
          entries(section:["articles"], orderBy:"postDate DESC", limit:$limit, offset:$offset, search:$search) {
            ...on articles_article_Entry {
              title,
              heroImage {
                url,
                mimeType
              },
              postDate,
              uri
            }
          }
        }`
    },
    {
        id: 'blogListPage',
        mapToProps: page,
        slug: true,
        query: `query getBlogListPage($slug:[String]){
          entries(type:["blogListPage"], slug:$slug) {
              ... on components_blogListPage_Entry {
                title,
                asset {
                  url,
                  mimeType
                },
                channel,
                categories,
                blpDescription,
              }
          }
        }`
    },
    {
        id: 'categoryPicker',
        mapToProps: categories,
        query: `{
          categories(groupId:1) { 
            ... on articleCategories_Category {
              id
              title
              slug
              uri,
            }
          }
        }`
    },
    {
      id: 'post',
      mapToProps: article,
      slug: true,
      query: `query getPost($slug:[String]){
                    entries(section: ["articles"], slug:$slug){
                    ...on articles_article_Entry{
                      title,
                      id,            
                      title,
                      slug,
                      uri,
                      dateCreated,      
                      articleCategories{
                        title,
                      },
                      articleKeywords,
                      articleDescription,
                      author{
                        name,
                        ... on User{
                          userPhoto{
                            url,
                            dateCreated,
                          },
                          bio,
                        },                   
                      },
                      heroImage{
                        url,
                        mimeType,
                      },
                      headerAlignment,
                      body{
                        __typename,
                        ...on body_text_BlockType{
                          bodyText:text,
                        },
                        ... on body_asset_BlockType{
                        loop,
                        autoplay,
                        controls:showVideoControls,
                          asset{
                            url,
                            mimeType,
                          },
                          position,
                          caption,
                        },
                        ... on body_quote_BlockType{
                          text,
                          position,
                        }
                      }
                    }
                    }
    }`,
    },
    {
        id: 'services',
        slug: true,
        mapToProps: solutions,
        query: `query getServices($slug:[String]){
 entries(slug:$slug, section:["services"]){
... on services_services_Entry
  {
    title,
    slug,
    uri,
    solutionsHeroImage{
      url,
    },
    headerAlignment,
    solutionsDescription,
    solutionsKeywords,
    solutionsBody{
      ... on solutionsBody_contentBlock_BlockType{
      loop,
      autoplay,
      controls:showVideoControls,
        image{
          url,
          mimeType,
        },
        callToActions{
          ... on callToActions_callToAction_Entry{
            uri,
            ctaButtonText,
            ctaStyle,
            ctaType{
              ... on ctaType_internalLink_BlockType{
                entry{
                  uri,
                  slug,
                }
              },
              ... on ctaType_externalLink_BlockType{
                ctaUrl,
              }
              ... on ctaType_popup_BlockType{
                embeddedHtml,
              }
              ... on ctaType_Inquiry_BlockType{
                form,
              }
              ... on ctaType_video_BlockType{
                youtubeId,
              }
            }
          }
        }
        text,
        imagePosition,
        imageEntryLink{
            uri,
        },
        imageWidthPercentage,
        backgroundColor{
          title,
          ... on colors_colors_Entry{
            colorValue,
          },
        },
        theme,
      },
      ... on solutionsBody_textBlurb_BlockType{
        blurbText,
        blurbAlignment,
        backgroundColor{
          ... on colors_colors_Entry{
            colorValue,
          },
        },
        theme,
      },
      ... on solutionsBody_solutionsAsset_BlockType{
      autoplay,
      controls:showVideoControls,
      loop,
        asset{
          url,
          mimeType,
        },
        position,
        caption,
      },
      ... on solutionsBody_story_BlockType{
        autoplay,
        controls:showVideoControls,
        loop,
        image{
          url,
          mimeType,
        },
        storyTitle,
        storySubtitle,
        storyText,
        buttonText,
        buttonUrl,
        buttonEntry{
          title,
          slug,
          uri,
        }
      }
    },
  }
}
}
     `,
    },
    {
     id: 'solutions',
     slug: true,
     mapToProps: solutions,
     query: `query getSolution($slug:[String]){
 entries(slug:$slug, section:["solutions"]){
... on solutions_solutions_Entry
  {
    title,
    slug,
    uri,
    solutionsHeroImage{
      url,
    },
    headerAlignment,
    solutionsDescription,
    solutionsKeywords,
    solutionsBody{
      ... on solutionsBody_contentBlock_BlockType{
      loop,
      autoplay,
      controls:showVideoControls,
        image{
          mimeType,
          url,
        },
        text,
        imagePosition,
        imageEntryLink{
            uri,
        },
         callToActions{
          ... on callToActions_callToAction_Entry{
            uri,
            ctaButtonText,
            ctaStyle,
            ctaType{
              ... on ctaType_internalLink_BlockType{
                entry{
                  uri,
                  slug,
                }
              },
              ... on ctaType_externalLink_BlockType{
                ctaUrl,
              }
              ... on ctaType_popup_BlockType{
                embeddedHtml,
              }
              ... on ctaType_Inquiry_BlockType{
                form,
              }
              ... on ctaType_video_BlockType{
                youtubeId,
              }
            }
          }
        }
        imageWidthPercentage,
        backgroundColor{
          title,
          ... on colors_colors_Entry{
            colorValue,
          },
        },
        theme,
      },
      ... on solutionsBody_textBlurb_BlockType{
        blurbText,
        blurbAlignment,
        backgroundColor{
          ... on colors_colors_Entry{
            colorValue,
          },
        },
        theme,
      },
      ... on solutionsBody_solutionsAsset_BlockType{
      autoplay,
      controls:showVideoControls,
      loop,
        asset{
          url,
          mimeType,
        },
        position,
        caption,
      },
      ... on solutionsBody_story_BlockType{
      autoplay, 
      controls:showVideoControls,
      loop,
        image{
          url,
          mimeType,
        },
        storyTitle,
        storySubtitle,
        storyText,
        buttonText,
        buttonUrl,
        buttonEntry{
          title,
          slug,
          uri,
        }
      }
    },
  }
}
}
     `,
    },
    {
        id: 'navigationBar',
        mapToProps: navigationBar,
        query: `query getNavigationBar {
  globalSets(handle:"navigationBar"){
    ... on navigationBar_GlobalSet{
      navigationBarItems{
        ... on navigationBarItems_item_BlockType{
          displayName
          navigationBarItemUrl
          navigationBarItemEntry{
            slug
            title
            uri
          }
          menu{
            title
            ... on menus_menus_Entry{
              blurb
              item{
                ... on item_item_BlockType{
                  icon{
                    url
                  }
                  heading
                  blurb
                  buttonText
                  itemEntry{
                    slug
                    uri
                    title
                  }
                  itemUrl
                }
              }
            }
          }
        }
        ... on navigationBarItems_divider_BlockType{
          id
        }
      }
    }
  }
}`
    },
    {
        id: 'navbarDropdowns',
        mapToProps: navbarDropdowns,
        query: `query getNavbarDropdowns{
  entries(section:["solutions", "services"]){
  __typename,
    ... on solutions_solutions_Entry{
      title, 
      slug,
      uri,
    },
    ... on services_services_Entry{
      title,
      slug,
      uri,
    }
  }
}`,
    },
    {
      id: 'consulting',
      mapToProps: consulting,
      slug: true,
      query: `query getConsulting($slug:[String]){
 entries(slug:$slug, section:["consulting"]){
... on consulting_consulting_Entry
  {
    title,
    slug,
    uri,
    solutionsHeroImage{
      url,
    },
    headerAlignment,
    solutionsDescription,
    solutionsKeywords,
    solutionsBody{
      ... on solutionsBody_contentBlock_BlockType{
        image{
          url,
        },
        text,
        imageEntryLink{
            uri,
        },
        imagePosition,
        imageWidthPercentage,
        backgroundColor{
          title,
          ... on colors_colors_Entry{
            colorValue,
          },
        },
        theme,
      },
      ... on solutionsBody_textBlurb_BlockType{
        blurbText,
        blurbAlignment,
        backgroundColor{
          ... on colors_colors_Entry{
            colorValue,
          },
        },
        theme,
      },
      ... on solutionsBody_solutionsAsset_BlockType{
        asset{
          url,
        },
        position,
        caption,
      }
    }
  }
}
}`,
  },
  {
    id: 'page',
    mapToProps: page,
    slug: true,
    query: `
      query getPage($slug:[String]){
        entries(section:["components"], slug:$slug) {
          title,
          sectionHandle,
          ...on components_defaultPage_Entry {
            solutionsDescription,
            solutionsHeroImage {
              id,
              url
            },
            solutionsKeywords,
            headerAlignment,
            solutionsBody {
              ... on solutionsBody_contentBlock_BlockType{
                loop,
                autoplay,
                controls:showVideoControls,
                image  {
                  url,
                  mimeType,
                },
                callToActions {
                  ... on callToActions_callToAction_Entry{
                    uri,
                    ctaButtonText,
                    ctaStyle,
                    ctaType {
                      ... on ctaType_internalLink_BlockType {
                        entry{
                          uri,
                          slug,
                        }
                      },
                      ... on ctaType_externalLink_BlockType{
                        ctaUrl,
                      }
                      ... on ctaType_popup_BlockType{
                        embeddedHtml,
                      }
                      ... on ctaType_Inquiry_BlockType{
                        form,
                      }
                      ... on ctaType_video_BlockType{
                        youtubeId,
                      }
                    }
                  }
                }
                text,
                imagePosition,
                imageEntryLink {
                  uri,
                },
                imageWidthPercentage,
                backgroundColor\t{
                  title,
                  ... on colors_colors_Entry{
                    colorValue,
                  },
                },
                theme,
              },
              ... on solutionsBody_textBlurb_BlockType{
                blurbText,
                blurbAlignment,
                backgroundColor{
                  ... on colors_colors_Entry{
                    colorValue,
                  },
                },
                theme,
              },
              ... on solutionsBody_solutionsAsset_BlockType{
              autoplay,
              controls:showVideoControls,
              loop,
                asset{
                  url,
                  mimeType,
                },
                position,
                caption,
              },
              ... on solutionsBody_story_BlockType{
                autoplay,
                controls:showVideoControls,
                loop,
                image{
                  url,
                  mimeType,
                },
                storyTitle,
                storySubtitle,
                storyText,
                buttonText,
                buttonUrl,
                buttonEntry{
                  title,
                  slug,
                  uri,
                }
              }
            },
          },
          ... on components_blogListPage_Entry {
            title,
            asset {
              url,
              mimeType
            },
            channel,
            categories,
            blpDescription,
          }
        }
      }
    `
  },
  {
    id: 'routes',
    mapToProps: (res) => {
      return {
        routes: res.data.entries.map((entry) => ({
          slug: entry.slug,
          type: entry.typeHandle,
        })),
      }
    },
    query: `query getRoutes{
      entries(section:["components"]) {
        slug,
        typeHandle
      }
    }`,
  },
]
