import React, { Component } from 'react'
import { withSizes } from '@oos-studio/website-common-components'

class ArticleDetails extends Component {
  formatDate = (_date) => {
    const date = new Date(_date )
    return {
      month: date.getMonth() + 1,
      day: date.getDate(),
      year: date.getFullYear()
    }
  }

  dateComponent = (moveDate) => {
    const { getStyle, data } = this.props
    const { formatDate } = this

    const date = formatDate(data.dateCreated)

    return (
     <div style={{...getStyle(styles.date), position: moveDate ? 'static' : 'absolute', left: moveDate ? 0 : -180 }}>
      <div style={styles.date.month}>
        <div>{date.month}</div>
      </div>
      <div style={getStyle(styles.date.rest)}>
        {`${date.day}/${date.year.toString().slice(-2)}`}
      </div>
    </div>
    )
  }

  render() {
    const { getStyle, data, sm, lg } = this.props
    const { dateComponent } = this
    const { formatDate } = this

    const date = formatDate(data.dateCreated)

    let categories = ''
    data.articleCategories.map((c, index) => {
      categories += index === data.articleCategories.length - 1 ? c.title : `${c.title}, `
    })

    const moveDate = window.innerWidth <= 1290 && window.innerWidth >= 768

    const dateComp = dateComponent(moveDate)

    return (
      <div style={getStyle(styles.container)}>
        {moveDate && !sm && dateComp}
        <div style={styles.titleSection}>
          {!moveDate && !sm && dateComp}
          <div style={getStyle(styles.title)}>
            {data.title}
          </div>
          <div style={getStyle(styles.subWrapper)}>
            {sm && <div style={styles.smDate}>
              {`${date.month}/${date.day}/${date.year.toString().slice(-2)}`}
            </div>}
            <div style={getStyle(styles.author)}>
              {`By ${data.author.name}`}
            </div>
            {!sm && <div style={getStyle(styles.categories)}>
              {categories}
            </div> }
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    textAlign: 'left',
    marginRight: 50,
    marginLeft: 50,
    maxWidth: 800,
    sm: {
      marginLeft: 35,
      marginRight: 35,
      flexDirection: 'column',
    },
  },
  titleSection: {
    position: 'relative',
    maxWidth: 800,
  },
  title: {
    color: '#007589',
    lineHeight: 1.25,
    fontSize: 52,
    fontFamily: '"Gotham A", "Gotham B"',
    fontWeight: 200,
    textTransform: 'uppercase',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#898A8D',
    paddingBottom: 45,
    sm: {
      fontSize: 38,
      borderBottomWidth: 0,
      paddingBottom: 20,
    }
  },
  subWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    sm: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    }
  },
  author: {
    display: 'inline',
    color: '#FF675D',
    fontSize: 24,
    fontFamily: 'Gotham XNarrow A, Gotham XNarrow B',
    fontWeight: 500,
    borderRightWidth: 1,
    borderRightColor: '#898A8D',
    borderRightStyle: 'solid',
    flex: 1,
    padding: 25,
    paddingTop: 25,
    paddingLeft: 0,
    borderBottomStyle: 'solid',
    borderBottomWidth: 0,
    borderBottomColor: '#898A8D',
    sm: {
      fontSize: 21,
      paddingTop: 10,
      borderRightWidth: 0,
      borderBottomWidth: 1,
      width: '100%',
    }
  },
  categories: {
    display: 'inline',
    flex: 2,
    padding: 10,
    height: '100%',
    paddingLeft: 30,
    borderLeftWidth: 0,
    borderLeftColor: '#898A8D',
    borderLeftStyle: 'solid',
    fontFamily: 'Gotham A, Gotham B',
    fontSize: 14,
    letterSpacing: 2,
    color: '#898A8D',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    width: 'auto',
    paddingTop: 5,
    paddingBottom: 5,
    margin: 15,
    marginBottom: 0,
    cursor: 'pointer',
    sm: {
      fontSize: 13,
    }
  },
  date: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginTop: 25,
    marginRight: 50,
    month: {
      backgroundColor: '#EFF078',
      borderRadius: '50%',
      width: 125,
      height: 125,
      color: '#FFFFFF',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 90,
      letterSpacing: 12,
      paddingLeft: 10,
      fontFamily: 'Gotham XNarrow A, Gotham XNarrow B',
      fontWeight: 400,
    },
    rest: {
      marginTop: 10,
      color: '#898A8D',
      textTransform: 'uppercase',
      letterSpacing: 2,
      fontFamily: '"Gotham A", "Gotham B"',
      fontWeight: 500,
      fontSize: 15,
      sm: {
        fontSize: 13,
      }
    },
  },
  smDate: {
    color: '#898A8D',
    fontWeight: 500,
    fontSize: 12,
    fontFamily: '"Gotham A", "Gotham B"',
  },
}

export default withSizes(ArticleDetails)
