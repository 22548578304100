import React, { Component } from 'react'
import { withSizes } from '@oos-studio/website-common-components'
import { withRouter, Link } from 'react-router-dom'
import { scrollToContactForm } from '../../utils/ScrollToContactForm'
import ModalVideo from 'react-modal-video'
import { Modal, ModalBody } from 'reactstrap'
import { connect } from 'react-redux'
import { setShowNavbar } from '../navigation/actions'

class CallToAction extends Component {
  state = {
    primaryHovered: [false, false],
    secondaryHovered: [false, false],
    videoOpen: false,
    videoId: null,
    popupOpen: false,
    popupEmbed: null,
  }

  onPrimaryHover = (index) => {
    let hovered = [false, false]
    hovered[index] = true
    this.setState({
      primaryHovered: hovered,
    })
  }

  onPrimaryLeaveHover = () => {
    this.setState({
      primaryHovered: [false, false],
    })
  }

  onSecondaryHover = (index) => {
    let hovered = [false, false]
    hovered[index] = true
    this.setState({
      secondaryHovered: hovered,
    })
  }

  onSecondaryLeaveHover = () => {
    this.setState({
      secondaryHovered: [false, false],
    })
  }

  onClickAction = (e, type) => {
    const { history, setShowNavbar } = this.props
    if (type.__typename !== 'ctaType_internalLink_BlockType') {
      e.preventDefault()
    }
    switch (type.__typename) {
      case 'ctaType_internalLink_BlockType':
        return
      case 'ctaType_externalLink_BlockType':
        if (type.ctaUrl) {
          window.open(type.ctaUrl, '__blank')
        }
        return
      case 'ctaType_Inquiry_BlockType':
        if (type.form === 'generalContact') {
          scrollToContactForm(history)
        }
        return
      case 'ctaType_popup_BlockType':
        setShowNavbar(false)
        this.setState({
          popupOpen: true,
          popupEmbed: type.embeddedHtml,
        })
        return
      case 'ctaType_video_BlockType':
        setShowNavbar(false)
        this.setState({
          videoOpen: true,
          videoId: type.youtubeId,
        })
        return
      default:
        return null
    }
  }

  togglePopupModal = () => {
    const { popupOpen } = this.state

    this.setState({
      popupOpen: !popupOpen,
    })
  }

  render() {
    const { data, getStyle, xs } = this.props
    const {
      onPrimaryHover,
      onPrimaryLeaveHover,
      onSecondaryHover,
      onSecondaryLeaveHover,
      onClickAction,
      togglePopupModal,
    } = this
    const {
      primaryHovered,
      secondaryHovered,
      videoOpen,
      videoId,
      popupOpen,
      popupEmbed,
    } = this.state

    return (
      <div style={getStyle(styles.container)}>
        <ModalVideo
          channel="youtube"
          isOpen={videoOpen}
          videoId={videoId}
          onClose={() => this.setState({ videoOpen: false, videoId: '' })}
        />
        <Modal
          toggle={togglePopupModal}
          isOpen={popupOpen}
          style={styles.modal}>
          <ModalBody style={styles.modalBody}>
            <div dangerouslySetInnerHTML={{ __html: popupEmbed }} />
          </ModalBody>
        </Modal>
        {data.map((button, index) => {
          const actionType = button.ctaType[0]
          let uri = ''
          if (
            actionType.__typename === 'ctaType_internalLink_BlockType' &&
            actionType.entry.length > 0
          ) {
            uri = `/${actionType.entry[0].uri}`
          }
          return button.ctaStyle === 'primary' ? (
            <Link
              to={uri}
              onClick={(e) => onClickAction(e, actionType)}
              onMouseEnter={() => onPrimaryHover(index)}
              onMouseLeave={onPrimaryLeaveHover}
              key={index}
              style={{
                ...getStyle(styles.primaryButton),
                backgroundColor: primaryHovered[index] ? '#8CB9C2' : '#007589',
                marginLeft: index === 1 && !xs ? 15 : 0,
                marginRight: index === 0 && !xs ? 15 : 0,
              }}>
              {button.ctaButtonText}
            </Link>
          ) : (
            <Link
              to={uri}
              onClick={(e) => onClickAction(e, actionType)}
              onMouseEnter={() => onSecondaryHover(index)}
              onMouseLeave={onSecondaryLeaveHover}
              key={index}
              style={{
                ...getStyle(styles.secondaryButtonWrapper),
                marginLeft: index === 1 && !xs ? 15 : 0,
                marginRight: index === 0 && !xs ? 15 : 0,
                color: secondaryHovered[index] ? '#86b3bc' : '#007589',
              }}>
              <span style={{ lineHeight: '45px' }} key={index + 1}>
                {button.ctaButtonText}
              </span>
              <i
                key={index + 2}
                style={{
                  ...styles.secondaryButtonArrow,
                  transform: secondaryHovered[index]
                    ? 'rotate(-45deg) translate3d(5px, 7px, 0)'
                    : 'rotate(-45deg) translate3d(0, 2px, 0)',
                  borderColor: secondaryHovered[index] ? '#86b3bc' : '#007589',
                }}
              />
            </Link>
          )
        })}
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 35,
    whiteSpace: 'nowrap',
    alignItems: 'unset',
    xs: {
      marginTop: 0,
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  modal: {
    width: 'fit-content',
    maxHeight: 'calc(100vh - 70px)',
    maxWidth: 'calc(100vw - 70px)',
    margin: 'auto',
    top: '50vh',
    transform: 'translateY(-50%)',
  },
  modalBody: {
    padding: 0,
  },
  primaryButton: {
    color: 'white',
    borderRadius: 6,
    borderWidth: 0,
    fontSize: 18,
    padding: 9,
    paddingLeft: 15,
    paddingRight: 15,
    width: 175,
    fontWeight: 300,
    fontFamily: 'Gotham A, Gotham B',
    transition: 'background-color 0.15s ease-out',
    marginTop: 0,
    cursor: 'pointer',
    textAlign: 'center',
    textDecoration: 'none',
    xs: {
      width: '100%',
      marginTop: 15,
    },
  },
  secondaryButtonWrapper: {
    cursor: 'pointer',
    fontFamily: '"Gotham A", "Gotham B"',
    fontSize: 18,
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: 2,
    height: 45,
    marginTop: 0,
    textAlign: 'center',
    textDecoration: 'none',
    xs: {
      width: '100%',
      marginTop: 15,
      textAlign: 'center',
    },
  },
  secondaryButtonArrow: {
    borderStyle: 'solid',
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    padding: 5,
    marginLeft: 7,
    marginTop: 11,
    transition: 'transform 0.25s',
  },
}

const mapDispatchToProps = (dispatch) => {
  return {
    setShowNavbar: (show) => dispatch(setShowNavbar(show)),
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(withRouter(withSizes(CallToAction)))
