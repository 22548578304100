import React, { Component } from 'react'
import { withSizes } from '@oos-studio/website-common-components'
import { withRouter, Link } from 'react-router-dom'
import deepmerge from 'deepmerge'

class GridItem extends Component {
  state = {
    hovered: false,
    url: '',
    isEntry: false,
  }
  componentDidMount() {
    const { url, entry } = this.props
    if (entry.length > 0) {
      this.setState({
        url: entry[0].uri,
        isEntry: true,
      })
    } else {
      this.setState({
        url: url,
        isEntry: false,
      })
    }
  }

  onMouseEnter = () => {
    this.setState({
      hovered: true,
    })
  }
  onMouseLeave = () => {
    this.setState({
      hovered: false,
    })
  }
  onClick = (e) => {
    const { url, isEntry } = this.state
    if (!isEntry && url) {
      e.preventDefault()
      window.open(url, '_blank')
    }
  }
  styles = () => {
    const { hovered, url } = this.state
    const { index, style, numItems } = this.props

    return {
      container: {
        cursor: url ? 'pointer' : 'default',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: hovered && url ? '#f1f1f1' : 'transparent',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 0,
        paddingBottom: 55,
        borderTop:
          style === 'default' && index > 2 ? 'solid 2px #C9C9C7' : 'none',
        borderRight:
          style === 'default' && ((numItems !== 2 && index !== 2 && index !== 5) || (numItems === 2 && index % 2 === 0))
            ? 'solid 2px #C9C9C7'
            : 'none',
        textDecoration: 'none',
        md: {
          borderTop:
            style === 'default' && index > 1 ? 'solid 2px #C9C9C7' : 'none',
          borderRight:
            style === 'default' && index % 2 === 0
              ? 'solid 2px #C9C9C7'
              : 'none',
        },
        xs: {
          paddingRight: 10,
          paddingLeft: 10,
          paddingBottom: 25,
        },
        xxs: {
          borderTop:
            style === 'default' && index > 0 ? 'solid 2px #C9C9C7' : 'none',
          borderRight: 'none',
        },
      },
      text: {
        color: '#007589',
        fontFamily: 'Gotham',
        fontSize: 24,
        textAlign: 'center',
        xs: {
          fontSize: 16,
        },
      },
      image: {
        height: 200,
        width: 200,
        transition: 'transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s',
        transform: hovered && url ? 'scale(1.125) translateZ(0)' : 'none',
        filter:
          style === 'simple' && !hovered
            ? 'brightness(0) invert(24%) sepia(9%) saturate(475%) hue-rotate(195deg) contrast(88%)'
            : 'none',
        WebkitBackfaceVisibility: 'hidden',
        WebkitTransform: 'translateZ(0)',
        MsTransform: 'translateZ(0)',
        xs: {
          height: 140,
          width: 140,
        },
      },
    }
  }

  render() {
    const { icon, text, getStyle, itemStyles } = this.props
    const { url } = this.state
    const { onMouseEnter, onMouseLeave, onClick, styles } = this

    const _styles = itemStyles ? deepmerge(styles(), itemStyles) : styles()

    return (
      <Link
        to={url}
        onClick={(e) => onClick(e)}
        onMouseEnter={() => onMouseEnter()}
        onMouseLeave={() => onMouseLeave()}
        style={getStyle(_styles.container)}>
        {icon.length > 0 && (
          <img src={icon[0].url} style={getStyle(_styles.image)} alt={text} />
        )}
        <div style={getStyle(_styles.text)}>{text}</div>
      </Link>
    )
  }
}

GridItem.defaultProps = {
  style: 'default',
}

export default withRouter(withSizes(GridItem))
